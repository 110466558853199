.cta {
  padding: calc($space-5 * 2) 0;
  @media screen and (max-width: 991px) {
    padding: calc($space-5 * 1.4);
  }
  @media screen and (max-width: 767px) {
    padding: $space-5 0;
  }
  position: relative;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    inset: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.3);
  }

  &__container {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-white;
    gap: calc($font-size / 2);
    max-width: 60%;
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  &__icono {
    img {
      width: 100px;
      height: auto;
    }
  }

  &__titulo {
    color: $color-white;
    text-transform: uppercase;
  }

  &__descripcion {
    margin-bottom: $space-3;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }
}
