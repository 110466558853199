.page-title {
  padding: 50px 0;
  color: $color-white;
  background-color: $color-primary;

  &__h {
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      font-size: 1.8rem;
      margin-bottom: 0rem;
    }
  }

  &__breadcrumbs {
    font-size: $font-size-smaller;
    a {
      color: $color-white;
      text-decoration: none;
      transition: $trans;
      &:hover {
        color: $color-grey-5;
        text-decoration: none;
        transition: $trans;
      }
    }
  }
}

.breadcrumbs-woo {
  padding-top: $space-1;
  padding-bottom: $font-size;
  font-size: $font-size-smaller;

  span {
    color: $color-black;
  }
  a {
    color: $color-primary;
    font-weight: $font-weight-bold;
    text-decoration: none;
    transition: $trans;
    &:hover {
      color: $color-black;
      text-decoration: none;
      transition: $trans;
    }
  }
}
