.widget {
  &__title {
    margin-bottom: 1.3rem;
    font-size: 1.2rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.6rem;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.widget--footer {
  margin-bottom: 2rem;
  .widget__title {
    font-weight: bold;
  }
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    li a {
      font-size: $font-size-small;
      color: $color-background;
      &:hover {
        color: rgba(200, 200, 200, 1);
      }
      @media screen and (max-width: 767px) {
        padding: 5px 10px;
        display: block;
      }
    }
  }
  .textwidget {
    a {
      color: $color-white;
    }
  }
}

.widget--page {
  @media screen and (max-width: 575px) {
    margin-bottom: 2rem !important;
  }
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      @media screen and (max-width: 767px) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      a {
        @media screen and (max-width: 767px) {
          padding: 4px 8px;
          background-color: $color-grey-4;
          color: $color-background;
          display: inline-flex;
          border-radius: 2px;
        }
      }
    }
    li.current-menu-item {
      a {
        font-weight: bold;
      }
    }
  }
}

.widgettitle {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  @media screen and (max-width: 575px) {
    margin-bottom: 0.3rem;
    font-size: 1rem;
  }
}

.social-icon {
  font-size: 30px !important;
}

.zoom-social-icons-list {
  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* widgets */
ins {
  text-decoration: none;
  border-bottom: 0px;
}

aside .widget {
  margin-bottom: 30px;
}
aside .widget h4.widgettitle {
  text-transform: uppercase;
  color: $color-black;
  font-weight: 700;
}
aside .widget p {
  color: $color-white;
}
aside .widget .menu {
  list-style: none;
  padding: 0px;
}
aside .widget .product-categories {
  list-style: none;
  padding: 0px;
}

.widget_product_categories .widgettitle,
.widget_recently_viewed_products .widgettitle {
  text-transform: uppercase;
  color: $color-black;
  font-weight: 700;
  font-size: $font-size-bigger !important;
  margin-bottom: 1rem;
}

ul.product-categories li a {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
}
ul.product-categories li ul.children {
  list-style: none;
  padding-left: 15px;
}
ul.product-categories li ul.children > li > a {
  list-style: none;
  font-size: 1.1rem;
  color: #919191;
}

/* Pimer nivel */
ul.product-categories > li.cat-item > a {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  color: $color-black;
}
ul.product-categories > li.cat-item > a:hover {
  text-decoration: none;
  color: $color-primary;
}

/* Seungdo nivel */
ul.product-categories > li.cat-item > ul.children > li.cat-item > a {
  font-size: 0.9rem;
  font-weight: bold;
  color: $color-primary-dark;
  text-transform: none;
}
ul.product-categories > li.cat-item > ul.children > li.cat-item > a:hover {
  text-decoration: none;
  color: $color-primary;
}
ul.product-categories > li.cat-item > ul.children {
  border-bottom: 1px dotted #000;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

/* Tercer nivel */
ul.product-categories
  > li.cat-item
  > ul.children
  > li.cat-item
  > ul.children
  a {
  font-size: 0.9rem;
  font-weight: normal;
  text-transform: inherit;
}
ul.product-categories
  > li.cat-item
  > ul.children
  > li.cat-item
  > ul.children:last-child {
  margin-bottom: 0.6rem;
  display: block;
}
ul.product-categories
  > li.cat-item
  > ul.children
  > li.cat-item
  > ul.children
  a:hover {
  text-decoration: none;
  color: $color-primary;
}

@media (max-width: 767px) {
  /* Pimer nivel */
  ul.product-categories > li.cat-item > a {
    margin-bottom: 0rem;
  }

  /* Seungdo nivel */
  ul.product-categories > li.cat-item > ul.children {
    padding-left: 0rem;
  }

  /* Tercer nivel */
  ul.product-categories > li.cat-item ul.children {
    padding-left: 0rem !important;
  }
  ul.product-categories
    > li.cat-item
    > ul.children
    > li.cat-item
    > ul.children {
    display: flex !important;
    flex-wrap: wrap;
  }
  ul.product-categories
    > li.cat-item
    > ul.children
    > li.cat-item
    > ul.children
    > li {
    margin: 0 5px 5px 0;
  }
  ul.product-categories
    > li.cat-item
    > ul.children
    > li.cat-item
    > ul.children
    > li
    > a {
    padding: 2px 5px;
    background-color: #717171;
    color: #fff;
    border-radius: 3px;
    font-size: 0.8rem;
  }
}
