.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-black;
        font-size: $font-size-bigger;
        font-weight: $font-weight-bold;
        text-decoration: none;
        padding: $font-size-smaller $font-size-small;
        @media screen and (max-width: 1400px) {
          padding-left: 14px;
          padding-right: 14px;
        }
        &:hover {
          color: $color-primary;
        }
        @media screen and (max-width: 1199px) {
          font-size: $font-size;
          padding-left: $font-size-smaller;
          padding-right: $font-size-smaller;
        }
      }
    }
    li.current-menu-item a {
      color: $color-primary;
    }
  }
}

.offcanvas {
  &-body {
    background-color: $color-white;
    padding-top: 0.5rem;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 2.5rem;
      line-height: 0;

      i {
        color: $color-black;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.6rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.3rem;
      }
      & > a {
        font-size: $header-2-res;
        font-weight: $font-weight-bolder;
        text-decoration: none;
        color: $color-black;

        &:hover {
          color: $color-primary;
        }
        // @media screen and (max-width: 767px) {
        //   font-size: $header-3;
        // }
      }
    }
  }

  &__logo {
    img {
      display: flex;
      width: 240px;
      margin: 1rem auto 0 auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 200px;
        transition: $trans;
      }
      @media screen and (max-width: 420px) {
        width: 160px;
        transition: $trans;
      }
    }
  }

  ul.product-categories {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

.nav-legal {
  &__nav {
    margin-top: $font-size;
    display: flex;
    justify-content: center;
    gap: calc($font-size/2);
    padding: 0;
    margin: 0;
    list-style: none;
    transition: $trans;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }

    a {
      font-size: $font-size;
      color: $color-grey-5;
      transition: $trans;

      &:hover {
        color: $color-primary;
        transition: $trans;

        &::before {
          color: $color-grey-5;
          transition: $trans;
        }
      }
      &::before {
        @media screen and (min-width: 768px) {
          content: "/";
          color: $color-grey-5;
          padding-right: 0.75rem;
          transition: $trans;
        }
      }
    }
  }
}

.c-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc($font-size * 0.5);
  &--home {
  }

  &--shop {
  }

  &__item {
  }
  &__link {
  }

  &__link--active {
  }
}
