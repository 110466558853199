.claim {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }
  &__content {
    width: 100%;
    margin-left: auto;
    line-height: 1.5;
    @media screen and (max-width: 991px) {
      width: 100%;
      margin-left: auto;
      line-height: 1.3;
    }
    &--left {
      @media screen and (min-width: 768px) {
        width: 80%;
        margin-right: auto;
        margin-left: 0;
      }
    }
  }

  &__titulo {
    font-size: $header-1;
    margin-bottom: $space-1;
    font-weight: $font-weight-medium;
    color: $color-black;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
      line-height: 1.5;
    }
  }
}

.galeria {
  margin-top: 4px;
  &__content {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 4px;
    margin-bottom: 4px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__content-item {
    grid-column: span 2;
    @media screen and (min-width: 768px) {
      &:nth-child(1) {
        grid-column: 1/5;
        grid-row: 1/3;
      }
      &:nth-child(7) {
        grid-column: 2/6;
        grid-row: 3/5;
      }
      &:nth-child(13) {
        grid-column: 3/5;
        grid-row: 5/7;
      }
    }
  }

  &__content-img {
    aspect-ratio: 16/11;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.galeria__content--1 {
  .galeria__content-item:nth-last-child(1) {
    @media screen and (max-width: 767px) {
      grid-column: 2/4;
    }
    @media screen and (max-width: 480px) {
      grid-column: 1 / span 2;
    }
  }
}

.especialistas {
  padding: $space-5 0;
  background-color: $color-primary;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }
  &--mb {
    margin-bottom: $space-1;
  }
  &__titulo {
    font-size: $display-1;
    color: $color-white;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
      margin-bottom: $space-1;
    }
  }
}

.caracteristicas {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (min-width: 481px) and (max-width: 991px) {
      flex-direction: row;
      align-items: flex-start;
      gap: $space-3;
      text-align: left;
    }
  }

  &__item-img {
    width: 80px;
    height: auto;
    margin-bottom: $font-size;
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (min-width: 481px) and (max-width: 991px) {
      align-items: flex-start;
      text-align: left;
    }
  }

  &__item-titulo {
    font-weight: $font-weight-bold;
    color: $color-black;
  }

  &__item-descripcion {
  }
}
