.hero {
  &__slide {
    aspect-ratio: 16/9;
    max-height: 70vh;
    position: relative;
    @media screen and (max-width: 767px) {
      aspect-ratio: 16/12;
    }
    @media screen and (max-width: 575px) {
      aspect-ratio: 1;
    }
    @media screen and (max-width: 480px) {
      aspect-ratio: 9/16;
    }
  }

  &__slide-bg {
    position: absolute;
    inset: 0;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: bottom center;
    }
  }

  &__slide-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    top: 0;
    bottom: 0px;
    z-index: 2;
  }

  &__slide-container {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-white;
    @media screen and (min-width: 992px) {
      max-width: 60%;
    }
  }

  &__slide-title {
  }

  &__slide-subtitle {
    margin-bottom: $space-2;
  }

  &__slide-actions {
    display: flex;
    align-items: center;
    gap: $space-1;
  }
}
