.gh-footer {
  padding: $space-5 0 $space-3 0;
  background-color: $color-black;
  @media screen and (max-width: 575px) {
    padding: $space-4 0 $space-2 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(3, 1fr);
      gap: $space-02;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-2;
      text-align: center;
      // padding-bottom: $space-2;
      // margin-bottom: $space-1;
    }
  }

  &__item {
    color: $color-white;
    .widget {
      margin-bottom: 0px !important;
    }
    .widgettitle {
      text-transform: uppercase;
      color: $color-primary;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      font-size: $font-size;
      margin-bottom: $space-1;
      @media screen and (max-width: 767px) {
        margin-bottom: calc($font-size/2);
      }
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: $color-white;
      &:hover {
        color: $color-primary;
      }
    }
  }

  &__item--1 {
    @media screen and (max-width: 1199px) {
      grid-column: 1 / 4;
    }
    @media screen and (max-width: 767px) {
      grid-column: 1/ 1;
      align-items: center;
      margin-bottom: $space-1;
    }
    img,
    svg {
      width: 100%;
      height: auto;
      max-width: 240px;
    }
    svg {
      path.cls-3 {
        fill: $color-white !important;
      }
    }
  }

  &__item--2 {
  }

  &__item--3 {
    .menu {
      list-style: none;
      padding: 0px;
      margin-bottom: 0;
    }
  }
  &__item--4 {
  }
}

.footer-copyright {
  padding-top: $space-3;
  &__content {
    color: $color-grey-5;
    display: flex;
    justify-content: flex-start;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }
  &__copy {
  }

  &__nav {
  }
}
