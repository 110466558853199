.hero-slider {
  .slick-prev,
  .slick-next {
    width: $display-2;
    height: $display-2;
    z-index: 4;
    @media screen and (max-width: 991px) {
      width: $display-3;
      height: $display-3;
    }
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "unicons-line";
    font-style: normal;
    font-weight: 400;
    font-size: $display-2;
    color: white;
    @media screen and (max-width: 991px) {
      font-size: $display-3;
    }
  }
  .slick-prev {
    left: 50px;
  }

  [dir="rtl"] .slick-prev {
    right: 50px;
    left: auto;
  }
  .slick-prev:before {
    content: "\ebb4";
  }
  [dir="rtl"] .slick-prev:before {
    content: "\ebb5";
  }

  .slick-next {
    right: 50px;
  }
  [dir="rtl"] .slick-next {
    right: auto;
    left: 50px;
  }
  .slick-next:before {
    content: "\ebb5";
  }
  [dir="rtl"] .slick-next:before {
    content: "\ebb4";
  }

  .slick-dots li {
    margin-right: 3px;
    margin-left: 3px;
  }

  .slick-dots li button:before {
    font-size: $font-size;
    opacity: 1;
    color: black;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: $color-primary;
  }
}
