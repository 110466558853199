.top {
  a {
    font-size: $font-size-small;
    color: $color-white;
    &:hover {
      color: $color-grey-5;
    }
  }
}

.header {
  padding: $space-1 0;
  background-color: $color-white;
  width: 100%;
  z-index: 99;
  position: sticky;
  top: 0;
  transition: $trans;

  @media screen and (max-width: 767px) {
    padding: $font-size 0;
  }

  @media screen and (max-width: 575px) {
    padding-top: $font-size;
    padding-bottom: $font-size;
  }

  &--sticky {
    padding: $font-size 0;
    box-shadow: $box-shadow;
    transition: $trans;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    img {
      width: 220px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 991px) {
        width: 180px;
        transition: $trans;
      }
      @media screen and (max-width: 420px) {
        width: 150px;
        transition: $trans;
      }
    }
  }

  &__nav {
    margin-left: auto;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__burger {
    margin-left: $space-1;
    i {
      color: $color-black;
      font-size: 1.75rem;
      transition: $trans;
      line-height: 1;
      &:hover {
        color: $color-primary;
        transition: $trans;
      }
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &__whatsapp {
    @include button;
    color: $color-white;
    background-color: $color-whatsapp-light;
    border-color: $color-whatsapp-light;
    margin-left: $font-size;
    display: flex;
    align-items: center;
    line-height: 1;
    font-weight: $font-weight-bold;
    padding: 6px 12px;

    i {
      font-size: 1.5rem;
    }
    &:hover {
      color: $color-white;
      background-color: darken($color-whatsapp-light, 5%);
      border-color: darken($color-whatsapp-light, 5%);
    }

    @media screen and (max-width: 991px) {
      margin-left: auto;
      font-size: $font-size-small;
      padding: 4px 8px;
      transition: $trans;
    }
    @media screen and (max-width: 480px) {
      font-size: $font-size-smaller;
      i {
        font-size: $font-size-big;
      }
    }
  }
}
