.h-categorias {
  padding: $space-5 0 $space-4 0;
  @media screen and (max-width: 991px) {
    padding: $space-4 0 $space-3 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0 $space-2 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0 $space-1 0;
  }

  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-3 $space-1;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3 $space-2;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $font-size;
  }

  &__item-thumb {
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  &__item-titulo {
    font-weight: $font-weight-bold;
  }
}

.all-categorias {
  padding: $space-4 0 $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-3 0 $space-4 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-2 0 $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-1 0 $space-2 0;
  }

  &__content {
  }
}
