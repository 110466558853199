.contacto {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    gap: $space-3;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__left {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-1;
  }

  &__right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-1;
  }
}

.bloque-info {
  &__titulo {
    color: $color-black;
    font-weight: $font-weight-medium;
  }

  &__subtitulo {
    text-transform: uppercase;
    color: $color-primary;
    font-size: $font-size-big;
  }

  &__descripcion {
  }

  &__datos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__datos-item {
    display: flex;
    align-items: center;
    i {
      font-size: 1.25rem;
    }
  }
}

.mapa {
  background-color: $color-black;
  iframe {
    width: 100%;
  }
}
